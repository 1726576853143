import React, { useRef, useEffect } from 'react';
import { TouchableWithoutFeedback, Animated, View } from 'react-native';
import { Text } from '@src/components/Text';
import { useT } from '@src/lib/useT';

export function Switch({
  value,
  onChangeValue,
  trueLabel,
  falseLabel,
}: {
  falseLabel?: string;
  trueLabel?: string;
  value: boolean;
  onChangeValue: (newValue: boolean) => void;
}) {
  const animValue = useRef(new Animated.Value(value ? 1 : 0));
  const t = useT();

  useEffect(() => {
    Animated.timing(animValue.current, {
      toValue: value ? 1 : 0,
      useNativeDriver: true,
      duration: 200,
    }).start();
  }, [value]);

  return (
    <View
      // @ts-expect-error
      dataSet={{ 'no-select': '' }}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          onChangeValue(!value);
        }}
      >
        <View
          style={{
            backgroundColor: 'white',
            flexDirection: 'row',
            padding: 5,
            paddingHorizontal: 10,
            borderRadius: 20,
            justifyContent: 'space-between',
            width: 64,
            height: 34,
            borderWidth: 2,
            borderColor: 'black',
          }}
        >
          <Animated.View
            style={{
              height: 30,
              width: 30,
              backgroundColor: 'black',
              borderRadius: 15,
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              transform: [
                {
                  translateX: animValue.current.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 30],
                  }),
                },
              ],
            }}
          />
          <Text
            weight="medium"
            style={{
              zIndex: 5,
              color: !value ? 'white' : 'black',
              left: -2,
              position: 'relative',
              top: 3,
            }}
            size={14}
          >
            {falseLabel ?? t('switch.no')}
          </Text>
          <Text
            weight="medium"
            style={{
              zIndex: 5,
              color: value ? 'white' : 'black',
              position: 'relative',
              top: 3,
              left: 2,
            }}
            size={14}
          >
            {trueLabel ?? t('switch.yes')}
          </Text>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}
