import React, { useContext, useState } from 'react';
import get from 'lodash/get';

import en from '@src/messages/en-US.json';
import es from '@src/messages/es-MX.json';

function getDefault() {
  if (
    global.window.location.pathname === '/contacto' ||
    global.window.location.pathname === '/confirmar-info' ||
    global.window.location.pathname === '/contactos'
  ) {
    return 'es';
  }
  return global.window.navigator.language.split('-')[0] ?? 'en';
}

const DEFAULT = getDefault();

const I18nContext = React.createContext({ lang: DEFAULT, setLang: (_lang: string) => {} });

export function I18nProvider({ children }: { children: React.ReactNode }) {
  const [lang, setLang] = useState(DEFAULT);
  return <I18nContext.Provider value={{ lang, setLang }}>{children}</I18nContext.Provider>;
}

const MESSAGES = {
  en,
  es,
};

export function useI18nContext() {
  return useContext(I18nContext);
}

export function translate(
  locale: string,
  path: string,
  variables: Record<string, string | number | null | undefined> = {},
) {
  return get(MESSAGES, [locale, path].join('.'));
}

export function useT() {
  const { lang } = useI18nContext();
  return (path: string, variables: Record<string, string | number | null | undefined> = {}) => {
    return translate(lang, path, variables);
  };
}
