import { initializeApp } from 'firebase/app';
import { getFirestore, doc, collection, setDoc, getDoc } from 'firebase/firestore';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyCu6tv9DWUV39HCMTrq1UMS0gElloUQtjk',
  authDomain: 'wedding-sofia-alec.firebaseapp.com',
  projectId: 'wedding-sofia-alec',
  storageBucket: 'wedding-sofia-alec.appspot.com',
  messagingSenderId: '244555818210',
  appId: '1:244555818210:web:51b66f4bdbb05ecfcf2abb',
});
const database = getFirestore(firebaseApp);

export async function writeData(collectionName: string, documentName: string, data: object) {
  const ref = doc(collection(database, collectionName), documentName);
  await setDoc(ref, data);
}

export async function readData<T>(
  collectionName: string,
  documentName: string,
): Promise<T | undefined> {
  if (!documentName) return undefined;
  const ref = doc(collection(database, collectionName), documentName);
  const result = await getDoc(ref);
  return result.data() as any;
}
