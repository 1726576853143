import React, { useMemo, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Link } from 'react-router-dom';
import set from 'lodash/set';

import { Section } from '@src/components/Section';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { PickerInput, TextInput } from '@src/components/TextInput';
import { useForm } from '@src/lib/useForm';
import { readData, writeData } from '@src/lib/firebase';
import { useT } from '@src/lib/useT';

function sanitizeEmail(email: string) {
  return email.trim().toLowerCase();
}

type RSVP = {
  guestName: string;
  rehearsal: boolean;
  rehearsalMeal: string;
  ceremony: boolean;
  ceremonyMeal: string;
  brunch: boolean;
  allergies: string;
};

function RSVPRow(props: {
  showRehearsal: boolean;
  showCeremony: boolean;
  showBrunch: boolean;
  validate: boolean;
  editableName?: boolean;
  allowKidMeal?: boolean;
  name: string;
  value: RSVP;
  onChangeValue: (newV: RSVP) => void;
}) {
  const value = props.value ?? {};
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const t = useT();
  return (
    <View row={!isMobile} style={{ marginBottom: 20, minHeight: 140 }} spacing={32}>
      <View
        style={{
          width: isMobile ? undefined : 250,
          justifyContent: 'center',
          alignItems: isMobile ? 'center' : undefined,
          alignSelf: 'flex-start',
        }}
      >
        {props.editableName ? (
          <TextInput
            label={t('rsvp.name_label')}
            value={value.guestName ?? ''}
            onChangeValue={(newValue) => {
              props.onChangeValue({ ...value, guestName: newValue });
            }}
            autoCompleteType="email"
          />
        ) : (
          <Text style={{}} weight="medium" size={24}>
            {props.name}
          </Text>
        )}
      </View>
      {props.showRehearsal ? (
        <View style={{ alignItems: 'center' }}>
          <Text style={{ marginBottom: 10 }}>{t('events.rehearsal.title')}</Text>
          <View row spacing={8}>
            <Button
              text={t('switch.no')}
              onPress={() => props.onChangeValue({ ...value, rehearsal: false })}
              variant={value.rehearsal === false ? 'solid' : undefined}
            />
            <Button
              text={t('switch.yes')}
              onPress={() => props.onChangeValue({ ...value, rehearsal: true })}
              variant={value.rehearsal ? 'solid' : undefined}
            />
          </View>
        </View>
      ) : (
        <View style={{ width: 50 }} />
      )}
      {props.showCeremony ? (
        <View style={{ alignItems: 'center' }}>
          <Text style={{ marginBottom: 10 }}>{t('events.ceremony.title')}</Text>
          <View row spacing={8}>
            <Button
              text={t('switch.no')}
              onPress={() => props.onChangeValue({ ...value, ceremony: false })}
              variant={value.ceremony === false ? 'solid' : undefined}
            />
            <Button
              text={t('switch.yes')}
              onPress={() => props.onChangeValue({ ...value, ceremony: true })}
              variant={value.ceremony ? 'solid' : undefined}
            />
          </View>
          {value.ceremony ? (
            <PickerInput
              label={t('rsvp.meal.label')}
              items={[
                t('rsvp.meal.chicken'),
                t('rsvp.meal.fish'),
                t('rsvp.meal.vegetarian'),
                props.allowKidMeal ? t('rsvp.meal.kid') : null!,
              ].filter((m) => !!m)}
              value={value.ceremonyMeal}
              onChangeValue={(newValue) => {
                props.onChangeValue({ ...value, ceremonyMeal: newValue });
              }}
              style={!value.ceremonyMeal && props.validate ? { borderColor: '#A3333D' } : undefined}
            />
          ) : null}
        </View>
      ) : null}
      {props.showBrunch ? (
        <View style={{ alignItems: 'center' }}>
          <Text style={{ marginBottom: 10 }}>{t('events.brunch.title')}</Text>
          <View row spacing={8}>
            <Button
              text={t('switch.no')}
              onPress={() => props.onChangeValue({ ...value, brunch: false })}
              variant={value.brunch === false ? 'solid' : undefined}
            />
            <Button
              text={t('switch.yes')}
              onPress={() => props.onChangeValue({ ...value, brunch: true })}
              variant={value.brunch ? 'solid' : undefined}
            />
          </View>
        </View>
      ) : null}
      <View style={{ flex: 1 }}>
        <TextInput
          value={value.allergies}
          onChangeValue={(newValue) => props.onChangeValue({ ...value, allergies: newValue })}
          label={t('rsvp.allergies_label')}
          placeholder={t('rsvp.allergies_placeholder')}
          multiline
          style={{ marginTop: 20 }}
          inputStyle={{ minHeight: 60 }}
        />
      </View>
    </View>
  );
}

export function RSVPScreen(props: {}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const [email, setEmail] = useState('');
  const [allergies, setAllergies] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const t = useT();
  const [invalid, setInvalid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState<GuestlistRow>();
  const [showOptionalPlus1, setShowOptionalPlus1] = useState(false);

  const initialValue = useMemo(() => {
    return result
      ? Object.entries(result as any)
          .filter(([k]) => k.startsWith('rsvp.'))
          .reduce((carry, [key, val]) => {
            set(carry, key.replace('rsvp.', ''), val);
            return carry;
          }, {})
      : result?.['SO First Name']
      ? ({ 0: {}, 1: {} } as any)
      : ({ 0: {} } as any);
  }, [result]);

  const { data, bind } = useForm(initialValue);

  async function lookupInfo(email: string): Promise<GuestlistRow | undefined> {
    const res = await readData<GuestlistRow | { refEmail: string }>(
      'addresses',
      sanitizeEmail(email),
    );
    if (res && 'refEmail' in res) {
      return lookupInfo(res.refEmail);
    }
    return res;
  }

  if (result) {
    const showRehearsal = true;
    const showCeremony = true;
    const showBrunch = true;
    return (
      <Section style={{ flex: 1 }} innerStyle={{ maxWidth: 1200 }}>
        {submitted ? null : (
          <Text size={32} style={{ textAlign: 'center' }}>
            {t('rsvp.result_heading')}
          </Text>
        )}
        <View
          style={{
            marginTop: 30,
            padding: isMobile ? 24 : 46,
            backgroundColor: 'rgba(255,255,255,0.6)',
          }}
        >
          {submitted ? (
            <View style={{ alignItems: 'center' }} spacing={16}>
              <Text style={{ textAlign: 'center', maxWidth: 400 }}>
                {t('rsvp.success')}
                <Link to="/travel">{t('rsvp.success_link')}</Link>
                {t('rsvp.success_two')}
              </Text>
            </View>
          ) : (
            <form ref={formRef}>
              <RSVPRow
                showRehearsal={showRehearsal}
                showCeremony={showCeremony}
                showBrunch={showBrunch}
                validate={invalid}
                name={[
                  result['Title'],
                  result['First Name'],
                  result['Middle Name'],
                  result['Last Name'],
                ]
                  .filter((n) => !!n)
                  .join(' ')}
                {...((bind('0') as any) as { value: RSVP; onChangeValue: (newV: RSVP) => void })}
              />
              {showOptionalPlus1 || result['SO First Name'] ? (
                <RSVPRow
                  showRehearsal={showRehearsal}
                  showCeremony={showCeremony}
                  showBrunch={showBrunch}
                  validate={invalid}
                  editableName={showOptionalPlus1}
                  name={[
                    result['SO Title'],
                    result['SO First Name'],
                    result['SO Middle Name'],
                    result['SO Last Name'],
                  ]
                    .filter((n) => !!n)
                    .join(' ')}
                  {...((bind('1') as any) as { value: RSVP; onChangeValue: (newV: RSVP) => void })}
                />
              ) : result['Optional +1'] === 'Yes' ? (
                <View style={{ alignItems: 'flex-start' }}>
                  <Button
                    text={t('rsvp.add_guest_button')}
                    onPress={() => setShowOptionalPlus1(true)}
                  />
                </View>
              ) : null}
              {result.Kids?.split(',')
                .filter((s) => !!s)
                .map((kid, i) => {
                  return (
                    <RSVPRow
                      showRehearsal={showRehearsal}
                      showCeremony={showCeremony}
                      showBrunch={showBrunch}
                      validate={invalid}
                      allowKidMeal
                      name={kid}
                      {...((bind((i + (result['Email SO'] ? 2 : 1)).toString()) as any) as {
                        value: RSVP;
                        onChangeValue: (newV: RSVP) => void;
                      })}
                      key={i}
                    />
                  );
                })}
              <View
                style={{
                  marginTop: 60,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  text={t('rsvp.submit_button')}
                  size="large"
                  onPress={async () => {
                    setInvalid(false);
                    formRef.current?.classList.remove('invalid');
                    const guestData = (Object.values(data) as RSVP[]).reduce<
                      Record<string, string | boolean>
                    >((carry, guest: RSVP, i) => {
                      carry[`rsvp.${i}.guestName`] =
                        i === 0
                          ? result['First Name']
                          : (guest.guestName || result['SO First Name']) ?? '';
                      carry[`rsvp.${i}.rehearsal`] = guest.rehearsal ?? false;
                      // carry[`rsvp.${i}.rehearsalMeal`] = guest.rehearsalMeal ?? '';
                      carry[`rsvp.${i}.ceremony`] = guest.ceremony ?? false;
                      carry[`rsvp.${i}.ceremonyMeal`] = guest.ceremonyMeal ?? '';
                      carry[`rsvp.${i}.brunch`] = guest.brunch ?? false;
                      carry[`rsvp.${i}.allergies`] = guest.allergies ?? '';
                      return carry;
                    }, {});

                    let valid = true;

                    Object.keys(guestData).forEach((key) => {
                      const value = guestData[key];
                      const attending = guestData[key.replace('Meal', '')];
                      if (key.includes('Meal') && !value && attending) {
                        valid = false;
                      }
                    });

                    if (valid) {
                      await writeData('addresses', sanitizeEmail(result!['Email Main Guest']), {
                        ...result,
                        ...guestData,
                        allergies,
                        rsvp: true,
                      });
                      setSubmitted(true);
                    } else {
                      setInvalid(true);
                      global.window.scrollTo({ top: 0, behavior: 'smooth' });
                      formRef.current?.classList.add('invalid');
                    }
                  }}
                />
              </View>
            </form>
          )}
        </View>
      </Section>
    );
  }

  async function lookup() {
    const lookupResult = await lookupInfo(email);
    if (!lookupResult) {
      setInvalid(true);
    } else {
      // @ts-expect-error
      if (lookupResult['Optional +1'] === 'Yes' && lookupResult['rsvp.1.guestName']) {
        setShowOptionalPlus1(true);
      }
      setResult(lookupResult);
    }
  }

  return (
    <Section style={{ flex: 1 }}>
      <Text size={32} style={{ textAlign: 'center' }}>
        {t('rsvp.heading')}
      </Text>
      {invalid ? (
        <Text style={{ textAlign: 'center', color: '#A3333D', marginVertical: 12 }}>
          {t('rsvp.no_result_error')}
        </Text>
      ) : null}
      <View
        style={{
          marginTop: 30,
          padding: isMobile ? 24 : 46,
          backgroundColor: 'rgba(255,255,255,0.6)',
        }}
      >
        <form ref={formRef}>
          <View spacing={8}>
            <Text weight="medium" size={20}>
              {t('rsvp.description')}
            </Text>
            <TextInput
              label={t('contact_info.email_label')}
              value={email}
              onChangeValue={setEmail}
              autoCompleteType="email"
              keyboardType="email-address"
              required
              onSubmitEditing={lookup}
            />
          </View>
          <View style={{ marginTop: 40, alignItems: 'center' }}>
            <Button text={t('rsvp.lookup_button')} size="large" variant="solid" onPress={lookup} />
          </View>
        </form>
      </View>
    </Section>
  );
}
