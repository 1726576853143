// https://coolors.co/f8f9fa-e9ecef-dee2e6-ced4da-adb5bd-6c757d-495057-343a40-212529
export const BACKGROUND = 'rgba(233, 236, 239, .32)' || '#d4dcd4';

export const shadow = {
  elevation: 2,
  shadowColor: '#242226',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.3,
  shadowRadius: 5,
};
