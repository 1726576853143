import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { BACKGROUND } from '@src/constants';

export function Section({
  style,
  innerStyle,
  ...props
}: {
  nativeID?: string;
  style?: StyleProp<ViewStyle>;
  innerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}) {
  return (
    <View
      style={[
        {
          justifyContent: 'center',
          padding: 40,
          backgroundColor: BACKGROUND,
          flexDirection: 'row',
        },
        style,
      ]}
    >
      <View
        style={[
          {
            maxWidth: 900,
            alignSelf: 'center',
            flex: 1,
          },
          innerStyle,
        ]}
        {...props}
      ></View>
    </View>
  );
}
