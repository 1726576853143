import React from 'react';
import { View as RNView } from 'react-native';

export function View(
  props: React.ComponentProps<typeof RNView> & {
    row?: boolean;
    spacing?: number;
    children?: React.ReactNode;
  },
) {
  const numChildren = React.Children.count(props.children);
  const children = props.spacing
    ? React.Children.map(props.children, (child, i) => {
        if (!child) return null;
        return React.cloneElement(child as any, {
          style: [
            (child as any)?.props?.style,
            i < numChildren - 1
              ? { [props.row ? 'marginRight' : 'marginBottom']: props.spacing }
              : null,
          ],
        });
      })
    : props.children;
  return (
    <RNView {...props} style={[props.row ? { flexDirection: 'row' } : null, props.style]}>
      {children}
    </RNView>
  );
}
