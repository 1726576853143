import React from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { Section } from '@src/components/Section';
import { useT } from '@src/lib/useT';
import { OurPathMap } from '@src/components/OurPathMap';

export function TravelScreen() {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const t = useT();
  return (
    <View>
      <Section style={{}}>
        <Text size={32} style={{ marginBottom: 20, textAlign: 'center' }}>
          {t('travel.where_to_stay.title')}
        </Text>
        <Text
          size={17}
          style={{ marginBottom: 30, textAlign: 'center', alignSelf: 'center', maxWidth: 600 }}
        >
          {t('travel.where_to_stay.explanation1')}
        </Text>
        {isMobile ? (
          <>
            <View
              style={{
                flexDirection: isMobile ? undefined : 'row',
                justifyContent: 'center',
                marginBottom: 20,
              }}
            >
              <View style={isMobile ? { marginBottom: 40 } : { marginRight: 40, width: 200 }}>
                <Text size={24} style={{ marginBottom: 10, textAlign: 'center' }} weight="medium">
                  {t('travel.where_to_stay.nameHotel')}
                </Text>
                <Text style={{ textAlign: 'center', marginBottom: 20 }}>
                  1080 Uptown Park Blvd{'\n'}
                  Houston, Texas{'\n'}
                  (713) 418-1000
                </Text>
                <Button
                  style={{ alignSelf: 'center' }}
                  to="https://g.page/HotelGranduca?share"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  text={t('travel.where_to_stay.book_online')}
                />
              </View>
            </View>
            <View style={{ flexDirection: isMobile ? undefined : 'row', justifyContent: 'center' }}>
              <View style={isMobile ? { marginBottom: 40 } : { marginRight: 40, width: 200 }}>
                <Text size={24} style={{ marginBottom: 10, textAlign: 'center' }} weight="medium">
                  Hyatt Regency Houston Galleria
                </Text>
                <Text style={{ textAlign: 'center', marginBottom: 20 }}>
                  2626 Sage Rd{'\n'}
                  Houston, TX{'\n'}
                  (832) 803-1234
                </Text>
                <Button
                  style={{ alignSelf: 'center' }}
                  to="https://g.page/Hyatt-Regency-Houston-Galleria?share"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  text={t('travel.where_to_stay.book_online')}
                />
              </View>
              <View style={isMobile ? {} : { width: 200 }}>
                <Text size={24} style={{ marginBottom: 10, textAlign: 'center' }} weight="medium">
                  The Westin Galleria Houston
                </Text>
                <Text style={{ textAlign: 'center', marginBottom: 20 }}>
                  5060 W Alabama St{'\n'}
                  Houston, TX{'\n'}
                  (713) 960-8100
                </Text>
                <Button
                  style={{ alignSelf: 'center' }}
                  to="https://goo.gl/maps/SCeERvBhdNZz7MbQ6"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  text={t('travel.where_to_stay.book_online')}
                />
              </View>
            </View>
          </>
        ) : (
          <View style={{}}>
            <OurPathMap hotelPreview />
          </View>
        )}
      </Section>
      <Section style={{ backgroundColor: 'white' }}>
        <Text size={32} style={{ marginBottom: 20, textAlign: 'center' }}>
          {t('travel.how_to_get_there.title')}
        </Text>
        <Text size={24} style={{ marginBottom: 10, textAlign: 'center' }} weight="medium">
          {t('travel.how_to_get_there.to_houston_title')}
        </Text>
        <Text
          size={17}
          style={{ marginBottom: 10, textAlign: 'center', alignSelf: 'center', maxWidth: 600 }}
        >
          {t('travel.how_to_get_there.airports')}
        </Text>
        <Text
          size={24}
          style={{
            marginTop: 30,
            marginBottom: 10,
            textAlign: 'center',
          }}
          weight="medium"
        >
          {t('travel.how_to_get_there.in_houston_title')}
        </Text>
        <Text
          size={17}
          style={{ marginBottom: 10, textAlign: 'center', alignSelf: 'center', maxWidth: 600 }}
        >
          {t('travel.how_to_get_there.land_transportation')}
          <a href="https://turo.com" target="_blank" rel="noopener noreferrer">
            Turo.com
          </a>
          .
        </Text>
      </Section>
      <Section style={{}}>
        <Text size={32} style={{ marginBottom: 20, textAlign: 'center' }}>
          {t('travel.what_to_do.title')}
        </Text>
        <Text size={17} style={{ textAlign: 'center', marginBottom: 20 }}>
          {t('travel.what_to_do.items_on_map')}
        </Text>
        <Button
          style={{ alignSelf: 'center' }}
          to="/?activities=true"
          text={t('travel.what_to_do.homepage_button')}
        />
      </Section>
    </View>
  );
}

const styles = StyleSheet.create({});
