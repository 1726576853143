import React, { useRef, useState } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { Text } from '@src/components/Text';
import { useT } from '@src/lib/useT';
import { OurPathMap } from '@src/components/OurPathMap';

export function SaveOurDate() {
  const { width, height: dynamicHeight } = useWindowDimensions();
  const isMobile = width < 800;
  const t = useT();

  return (
    <View>
      <View style={[styles.container, { paddingTop: 60 }]}>
        <View
          style={{
            zIndex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            padding: isMobile ? 24 : 46,
            backgroundColor: 'rgba(255,255,255,0.2)',
            alignSelf: 'center',
          }}
          pointerEvents="none"
        >
          {isMobile ? (
            <>
              <Text size={46} weight="regular" style={{ letterSpacing: 10, textAlign: 'center' }}>
                SOFÍA{'\n'}+{'\n'}ALEC
              </Text>
            </>
          ) : (
            <Text size={46} weight="regular" style={{ letterSpacing: 10, textAlign: 'center' }}>
              SOFÍA + ALEC
            </Text>
          )}
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text size={20} style={{ letterSpacing: 2, textAlign: 'center' }}>
              {t('save_our_date.date')}
            </Text>
            <Text size={20} style={{ letterSpacing: 2 }}>
              {t('save_our_date.location')}
            </Text>
          </View>
        </View>
        <View
          style={[
            isMobile
              ? { paddingVertical: 50, padding: 20, backgroundColor: '#d2d5d5' }
              : StyleSheet.absoluteFillObject,
            { height: dynamicHeight },
          ]}
        >
          <OurPathMap />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fefefe',
  },
});
