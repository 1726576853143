import merge from 'lodash/merge';
import { useState } from 'react';

export function useForm(defaultData: object) {
  const [formData, setFormData] = useState({});
  const data: any = merge({}, defaultData, formData);

  function bind(key: string) {
    return {
      value: data[key] as string,
      onChangeValue: (newValue: string) => {
        setFormData((d) => {
          return { ...d, [key]: newValue };
        });
      },
    };
  }

  return {
    data,
    bind,
  };
}
