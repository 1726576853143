import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  findNodeHandle,
  StyleProp,
  StyleSheet,
  TextInput as RNTextInput,
  View,
  ViewStyle,
} from 'react-native';

import { Text } from '@src/components/Text';

export function PickerInput({
  value,
  items,
  onChangeValue,
  label,
  ...props
}: {
  value?: string;
  label?: string;
  items: string[];
  onChangeValue: (s: string) => void;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <select
      {...props}
      style={
        StyleSheet.flatten([
          {
            backgroundColor: 'transparent',
            borderWidth: 2,
            borderRadius: 5,
            borderColor: 'black',
            marginTop: 15,
            marginBottom: 1,
            padding: 12,
          },
          props.style,
        ]) as any
      }
      value={value}
      onChange={(e) => onChangeValue(e.target.value)}
    >
      {label ? <option defaultValue="">{label}</option> : null}
      {items.map((i) => (
        <option key={i}>{i}</option>
      ))}
    </select>
  );
}

export function TextInput({
  label,
  value,
  onChangeValue,
  ...props
}: {
  label: string;
  value?: string;
  onChangeValue: (s: string) => void;
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  required?: boolean;
} & React.ComponentProps<typeof RNTextInput>) {
  const [labelPosition] = useState(() => new Animated.Value(0));
  const [hidePlaceholder, setHidePlaceholder] = useState(false);
  const [focused, setFocused] = useState(false);
  const domRef = useRef(null);

  useEffect(() => {
    const handle = (findNodeHandle(domRef.current) as any) as HTMLInputElement | null;
    if (handle) {
      if (props.required) {
        handle.required = true;
      } else {
        handle.required = false;
      }
    }
  }, [props.required]);

  useEffect(() => {
    if (focused || value) {
      setHidePlaceholder(true);
      Animated.timing(labelPosition, {
        duration: 200,
        toValue: 1,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(labelPosition, {
        duration: 200,
        toValue: 0,
        useNativeDriver: false,
      }).start(() => {
        setHidePlaceholder(false);
      });
    }
  }, [focused, value, labelPosition]);

  return (
    <View
      style={[
        {
          flex: 1,
          marginTop: 16,
        },
        props.style,
      ]}
    >
      <RNTextInput
        ref={domRef}
        placeholder={focused || hidePlaceholder ? undefined : label}
        value={value ?? ''}
        onChangeText={onChangeValue}
        {...props}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={[
          {
            borderColor: 'black',
            borderWidth: 2,
            borderRadius: 5,
            padding: 12,
            fontFamily: 'Avenir',
          },
          props.inputStyle,
        ]}
      />
      <Animated.View
        style={{
          padding: 12,
          position: 'absolute',
          opacity: labelPosition.interpolate({ inputRange: [0, 0.01, 1], outputRange: [0, 1, 1] }),
          transform: [
            {
              translateY: labelPosition.interpolate({ inputRange: [0, 1], outputRange: [0, -30] }),
            },
            {
              translateX: labelPosition.interpolate({ inputRange: [0, 1], outputRange: [0, -15] }),
            },
            { scale: labelPosition.interpolate({ inputRange: [0, 1], outputRange: [1, 0.8] }) },
          ],
        }}
        pointerEvents="none"
      >
        <Text weight="medium" size={13}>
          {label}
        </Text>
      </Animated.View>
    </View>
  );
}
