import React from 'react';
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native';

import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { Section } from '@src/components/Section';
import { useT } from '@src/lib/useT';
import Location from '@src/assets/location.svg';
import Clock from '@src/assets/clock.svg';
import Hanger from '@src/assets/hanger.svg';

import Man from '@src/assets/man.svg';
import Woman from '@src/assets/woman.svg';

function EventDetailSection(props: { icon: string; children: React.ReactNode }) {
  return (
    <View style={{ flex: 1 }}>
      <Image
        source={{ uri: props.icon }}
        style={{ width: 30, height: 30, alignSelf: 'center', marginBottom: 20 }}
      />
      {props.children}
    </View>
  );
}

function Divider() {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  return isMobile ? (
    <View
      style={{
        // height: 1,
        marginVertical: 20,
        // backgroundColor: 'black',
        // width: '50%',
        // alignSelf: 'center',
      }}
    />
  ) : (
    <View style={{ width: 1, marginHorizontal: 20, backgroundColor: 'black', height: 100 }} />
  );
}

function EventDetails(props: {
  title: string;
  inviteOnly?: boolean;
  date: string;
  time: string;
  description: string;
  attire: string;
  attireMen?: string;
  attireWomen?: string;
  locationName?: string;
  locationAddr?: string;
  locationCity?: string;
  mapLink?: string;
  hosts?: string;
  uberCode1: string;
  uberCode2: string;
}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const t = useT();

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <View style={{ marginBottom: 20 }}>
        <Text size={32} style={{ letterSpacing: 4, textAlign: 'center' }} weight="medium">
          {props.title}
        </Text>
        {props.inviteOnly ? (
          <Text size={16} style={{ letterSpacing: 1, textAlign: 'center', fontStyle: 'italic' }}>
            {t('events.by_invite_only')}
          </Text>
        ) : null}
      </View>
      {props.hosts ? (
        <Text
          size={16}
          style={{ letterSpacing: 1, textAlign: 'center', fontStyle: 'italic', marginBottom: 20 }}
        >
          {props.hosts}
        </Text>
      ) : null}
      <Text
        size={18}
        style={{
          letterSpacing: 2,
          textAlign: 'center',
          marginBottom: 20,
          maxWidth: 500,
          alignSelf: 'center',
        }}
      >
        {props.description}
      </Text>
      <View style={{ flexDirection: isMobile ? undefined : 'row' }}>
        <EventDetailSection icon={Clock}>
          <Text size={16} style={{ letterSpacing: 2, textAlign: 'center', marginBottom: 20 }}>
            {props.date}
          </Text>
          <Text size={16} style={{ letterSpacing: 0, textAlign: 'center', marginBottom: 20 }}>
            {props.time}
          </Text>
        </EventDetailSection>
        <Divider />
        <EventDetailSection icon={Location}>
          <Text size={16} style={{ letterSpacing: 2, textAlign: 'center' }}>
            {props.locationName}
          </Text>
          <Text size={16} style={{ letterSpacing: 2, textAlign: 'center' }}>
            {props.locationAddr}
          </Text>
          <Text size={16} style={{ letterSpacing: 2, textAlign: 'center', marginBottom: 20 }}>
            {props.locationCity}
          </Text>
          {props.mapLink ? (
            <View style={{ alignItems: 'center' }}>
              <Button text={t('events.map_button')} to={props.mapLink} />
              <a
                href={`https://r.uber.com/${props.uberCode1}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginTop: 10 }}
              >
                {t('events.uber_code_to')}
              </a>
              <a
                href={`https://r.uber.com/${props.uberCode2}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginTop: 10 }}
              >
                {t('events.uber_code_from')}
              </a>
            </View>
          ) : null}
        </EventDetailSection>
        <Divider />
        <EventDetailSection icon={Hanger}>
          <Text size={16} style={{ letterSpacing: 2, textAlign: 'center', marginBottom: 20 }}>
            {props.attire}
          </Text>
          {props.attireMen && props.attireWomen ? (
            <View style={{ alignSelf: 'center' }}>
              <View style={{ alignItems: 'center', flexDirection: 'row', marginBottom: 10 }}>
                <Image source={{ uri: Woman }} style={{ width: 20, height: 20, marginRight: 10 }} />
                <Text style={{ flex: 1 }}>{props.attireWomen}</Text>
              </View>
              <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                <Image source={{ uri: Man }} style={{ width: 20, height: 20, marginRight: 10 }} />
                <Text style={{ flex: 1 }}>{props.attireMen}</Text>
              </View>
            </View>
          ) : (
            <View style={{ alignSelf: 'center' }}>
              <View style={{ alignItems: 'center', flexDirection: 'row', marginBottom: 10 }}>
                <Image source={{ uri: Woman }} style={{ width: 20, height: 20, marginRight: 10 }} />
                <Image source={{ uri: Man }} style={{ width: 20, height: 20, marginRight: 10 }} />
                <Text style={{ flex: 1 }}>{props.attireMen}</Text>
              </View>
            </View>
          )}
        </EventDetailSection>
      </View>
    </View>
  );
}

export function EventsScreen() {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const t = useT();
  return (
    <View>
      <Section style={{}}>
        <EventDetails
          title={t('events.rehearsal.title')}
          date={t('events.rehearsal.date')}
          time={t('events.rehearsal.time')}
          description={t('events.rehearsal.description')}
          attire={t('events.rehearsal.attire')}
          attireMen={t('events.rehearsal.attireMen')}
          attireWomen={t('events.rehearsal.attireWomen')}
          hosts={t('events.rehearsal.hosts')}
          locationName={t('events.rehearsal.locationName')}
          locationAddr="2401 Claremont Lane"
          locationCity={t('events.rehearsal.locationCity')}
          mapLink="https://goo.gl/maps/VGCq6sgBZzCbakWe7"
          uberCode1="rW1l4S8jDQ6"
          uberCode2="rW5cnWMMbIr"
        />
      </Section>
      <Section style={{ backgroundColor: 'white' }}>
        <EventDetails
          title={t('events.ceremony.title')}
          date={t('events.ceremony.date')}
          time={t('events.ceremony.time')}
          description={t('events.ceremony.description')}
          attire={t('events.ceremony.attire')}
          attireMen={t('events.ceremony.attireMen')}
          attireWomen={t('events.ceremony.attireWomen')}
          hosts={t('events.ceremony.hosts')}
          locationName={t('events.ceremony.locationName')}
          locationAddr="901 W 34th St"
          locationCity={t('events.ceremony.locationCity')}
          mapLink="https://g.page/TheBellTowerOn34th?share"
          uberCode1="rHxhUcxbYA7"
          uberCode2="rpUOSajNElm"
        />
      </Section>
      <Section style={{}}>
        <EventDetails
          title={t('events.brunch.title')}
          date={t('events.brunch.date')}
          time={t('events.brunch.time')}
          description={t('events.brunch.description')}
          attire={t('events.brunch.attire')}
          attireMen={t('events.brunch.attireMen')}
          hosts={t('events.brunch.hosts')}
          locationName={t('events.brunch.locationName')}
          locationAddr="6501 Vanderbilt St"
          locationCity={t('events.brunch.locationCity')}
          mapLink="https://goo.gl/maps/cTNeEVrXTKsgsyCw7"
          uberCode1="rNdHgZHMSRu"
          uberCode2="rap9N0Vz3dm"
        />
      </Section>
    </View>
  );
}

const styles = StyleSheet.create({});
