import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMapboxGl, { Layer, Feature, GeoJSONLayer, Marker, ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LngLatBounds } from 'mapbox-gl';
import { Image, ScrollView, useWindowDimensions, View } from 'react-native';
import { point as turfPoint } from '@turf/helpers';
import along from '@turf/along';
import bearing from '@turf/bearing';
import length from '@turf/length';
import type MapboxJS from 'mapbox-gl';

const alec = require('@src/assets/alec.jpeg');
const sofia = require('@src/assets/sofia.jpeg');

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYXBwc2NhbnZhcyIsImEiOiJjanJpNmRybjQzMXJjNDlwODJtMHNzbzRqIn0.n0eU6Y5EEU7RfAYuMvw0DQ',
  attributionControl: false,
  renderWorldCopies: false,
  // interactive: false,
});

// import route from '@src/path.json';
// import california from '@src/california.json';
// import mexico from '@src/mexico.json';
// import spain from '@src/spain.json';
import { shadow } from '@src/constants';
import { useI18nContext, useT } from '@src/lib/useT';
import { Text } from '@src/components/Text';
import { Button } from '@src/components/Button';

const BAY_AREA = [-122.36709594726562, 37.805986463750315] as [number, number];
const MADRID = [-3.7034225463867183, 40.414541645611635];
const CDMX = [-99.1351318359375, 19.423858594979777];
const BAR_BURA = [-116.75391310223569, 31.95178486981311];
const VENUE = [-95.41589635767276, 29.81758591012734];

type PlaceInfo = {
  avatar: 'alec' | 'sofia' | 'both' | '';
  hotelPreview?: boolean | string;
  name: string;
  nameES?: string;
  description: string;
  descES: string;
  icon?: string;
  lnglat: [number, number];
};

const BAY_AREA_PLACES: Array<PlaceInfo> = [
  {
    avatar: 'alec',
    name: 'Quizlet',
    description:
      'I worked here for two years and three months! It was my first job out of Stanford.',
    descES:
      '¡Aquí trabajé durante dos años y tres meses! Fue mi primer trabajo después de graduarme de Stanford.',
    lnglat: [-122.39333181742438, 37.78281126685929],
  },
  {
    avatar: 'sofia',
    name: 'University of California, Berkeley',
    description: 'I went to college here!',
    descES: '¡Aquí fui a la universidad!',
    lnglat: [-122.25855063114591, 37.87209397074291],
  },
  {
    avatar: 'alec',
    name: "Patxi's Pizza",
    description:
      "My favorite deep dish pizza in San Francisco! If you're ever here, try the Matt Cain, it's a meatlover's pizza!",
    descES:
      "¡My pizza deep dish favorita en San Francisco! Si alguna vez van, prueben la pizza Matt Cain, es una pizza meatlover's",
    lnglat: [-122.43580994502108, 37.80129234736503],
  },
  {
    avatar: 'alec',
    name: 'Il Casaro Pizzeria',
    description: 'Authentic Neapolitan pizza has never been this good.',
    descES: 'La auténtica pizza napolitana jamás ha sabido mejor que en este lugar. ',
    lnglat: [-122.40800080175792, 37.80027506474338],
  },
  {
    avatar: 'alec',
    name: "Oren's Hummus",
    description: 'In my opinion, the best hummus in the world.',
    descES: 'En mi opinión, aquí está el mejor hummus del mundo.',
    lnglat: [-122.4020667838395, 37.787742279295394],
  },
  {
    avatar: 'alec',
    name: 'Stanford University',
    description: 'I went to college here!',
    descES: '¡Aquí fui a la universidad!',
    lnglat: [-122.16971900232168, 37.42766191999712],
  },
  {
    avatar: 'alec',
    name: 'Mission High School',
    description:
      'I played in a casual volleyball league while living in San Francisco and had many games in the gym here.',
    descES:
      'Jugaba en una liga local de volleyball mientras vivía en San Francisco y jugué muchos partidos en este gimnasio.',
    lnglat: [-122.42727755130637, 37.76185228900185],
  },
  {
    avatar: 'both',
    name: 'Dolores Park',
    description:
      'We enjoyed time in this park and hanging out with friends—albeit not together yet!',
    descES: 'Disfrutamos mucho de este parque con nuestros amigos, ¡aunque aún no nos conocíamos!',
    lnglat: [-122.42688684448993, 37.7595097410066],
  },
  {
    avatar: 'sofia',
    name: 'Caffè Strada',
    description: 'The times I was not in class, I was at Strada, my second home while at Berkeley!',
    descES: 'Cuando no estaba en clase, estaba en Strada, ¡mi segunda casa en Berkeley!',
    lnglat: [-122.2548482733394, 37.86929419586847],
  },
  {
    avatar: 'sofia',
    name: "Artichoke Basille's Pizza",
    description:
      'I pronounce it "art-ee-choke-ee" because it\'s fun! The pizza slices here that were the size of my face were my favorite late-night snack.',
    descES:
      'Lo pronuncio "art-ee-choke-ee," porque me divierte decirlo así. Las rebanadas de pizza del tamaño de mi cara de Artichoke\'s eran mi snack nocturno preferido.',
    lnglat: [-122.25682627340018, 37.86811653306032],
  },
  {
    avatar: 'sofia',
    name: 'The Cheeseboard Pizza',
    description: 'In my opinion, one of the best pizzas in the world.',
    descES: 'En mi opinión, aquí está una de las mejores pizzas del mundo.',
    lnglat: [-122.26941502914457, 37.880024708412684],
  },
  {
    avatar: 'sofia',
    name: 'Kappa Alpha Theta',
    description:
      'My home for the last two years of college and where I met some of my best friends to this day, including five of my eight bridesmaids!',
    descES:
      'Esta fue mi casa durante los últimos dos años en Berkeley y en donde conocí a varias de mis mejores amigas, ¡incluyendo cinco de mis ocho damas!',
    lnglat: [-122.25355401565126, 37.868808014296036],
  },
  {
    avatar: 'sofia',
    name: 'La Burrita Taquería',
    description:
      'Also known as "Labo," this Mexican joint became my go-to spot for food that tasted like home.',
    descES:
      'Conocido también como "Labo" este restaurante mexicano se volvió mi lugar favorito para encontrar un recuerdo de casa mientras estaba en Berkeley.',
    lnglat: [-122.25743148681589, 37.86800370294037],
  },
  {
    avatar: 'sofia',
    name: 'Triple Rock Brewery',
    description:
      'A usual spot to visit on Thursdays with my friends to enjoy good times, good drinks, and great food.',
    descES:
      'El lugar tradicional para visitar los jueves, en donde mis amigos y yo disfrutamos de excelentes momentos, bebidas y comida.',
    lnglat: [-122.26872311565135, 37.87363617368791],
  },
];

const MADRID_PLACES: Array<PlaceInfo> = [
  {
    avatar: 'both',
    name: 'Tribunal Metro Station',
    nameES: 'Estación del Metro: Tribunal',
    description: 'This is where we met for the first time on the night of October 14th 2017!',
    descES: '¡Aquí nos conocimos por primera vez la noche de octubre 14 del 2017!',
    lnglat: [-3.7012209174268773, 40.42635356018139],
  },
  {
    avatar: 'alec',
    name: 'Lingokids',
    description: 'This is where I worked, building an app for kids to learn English.',
    descES: 'Aquí trabajé, construyendo una app para enseñarle inglés a niños pequeños.',
    lnglat: [-3.7140529309203836, 40.427446556863075],
  },
  {
    avatar: 'sofia',
    name: 'Colegio Santa María la Blanca',
    description:
      'I taught English here to some very sweet (and sometimes naughty) Spanish fourth graders.',
    descES: 'Aquí le enseñaba inglés a niños muy tiernos (y algo traviesos) de cuarto de primaria.',
    lnglat: [-3.6971096597533926, 40.51169903678548],
  },
  {
    avatar: 'both',
    name: 'La Musa Malasaña',
    description:
      'One of our favorite restaurants with a famous dish called the "bombas," which are baked potatoes with meat and an amazing sauce on the inside.',
    descES:
      'Uno de nuestros restaurantes favoritos en donde descubrimos las "bombas," que son unas papas al horno con carne y una salsa deliciosa adentro.',
    lnglat: [-3.704316173249379, 40.42898135066538],
  },
  {
    avatar: 'both',
    name: 'NAP',
    description: 'One of the best Neapolitan pizzas you will ever taste, in our opinion!',
    descES: '¡En nuestra opinión, aquí está una de las mejores pizza napolitanas que probarán!',
    lnglat: [-3.702049615578308, 40.43222364157094],
  },
  {
    avatar: 'sofia',
    name: 'La Gringa',
    description:
      'We attended trivia here every Wednesday night. Alec, as you can imagine, carried the team forward!',
    descES:
      'Íbamos a trivia aquí todos los miércoles por la noche, ¡como lo podrán imaginar, Alec lideraba al equipo!',
    lnglat: [-3.707894173249467, 40.42628742412877],
  },
  {
    avatar: 'sofia',
    name: 'Takos Al Pastor',
    description:
      'My mom discovered this place! Alec and I firmly believe this restaurant has the best Mexican tacos in the world. Yes, you read that correctly, the best tacos in the world are not in Mexico!',
    descES:
      '¡Mi mamá descubrió este lugar! Alec y yo consideramos que este restaurante tiene los mejores tacos mexicanos del mundo. ¡Es correcto, lo leyeron bien, los mejores tacos no están en México!',
    lnglat: [-3.7038136306838356, 40.41961187381788],
  },
  {
    avatar: 'alec',
    name: 'Núñez de Balboa Metro Station',
    nameES: 'Estación del Metro: Núñez de Balboa',
    description:
      'I lived with the Azcaray family (meet them at the wedding!) close to this metro station during my college study abroad. It became my home away from home and one of my main reasons for moving from San Francisco to Madrid.',
    descES:
      'Viví con la familia Azcaray (¡conózcanlos en la boda!) cuando estuve de intercambio en Stanford. Fue mi casa fuera de casa y uno de las razones principales para mudarme de San Francisco a Madrid.',
    lnglat: [-3.682676782286401, 40.43318347686995],
  },
];

const CDMX_PLACES: Array<PlaceInfo> = [
  {
    avatar: 'alec',
    name: 'Churros el Moro',
    description: 'The best churros I have ever tasted and where I realized I wanted to be Mexican!',
    descES: '¡Los mejores churros que he probado y en donde me di cuenta que quería ser mexicano!',
    lnglat: [-99.2701120313917, 19.357235311465345],
  },
  {
    avatar: 'both',
    name: 'Bazaar del sábado',
    description:
      'Where we brought all of our amazing overseas visitors to enjoy art, food, and a wonderful Saturday.',
    descES:
      'A donde llevamos a todos nuestros visitantes extranjeros para disfrutar del arte, la comida y un maravilloso sábado.',
    lnglat: [-99.1926249846581, 19.345840030409544],
  },
  {
    avatar: 'both',
    name: 'Polanquito',
    description:
      'The neighborhood where we often walk around and enjoy some Joselo coffee and Roxy ice cream.',
    descES: 'El barrio en donde disfrutamos del café de Joselo y del helado de Roxy.',
    lnglat: [-99.19922817382006, 19.430017843264334],
  },
  {
    avatar: 'alec',
    name: 'Los Tulipanes',
    description:
      "The second best tres leches cake one will ever taste. My dad's tres leches takes first place! ",
    descES: 'El segundo mejor pastel de tres leches (el mejor es el que hace mi papá).',
    lnglat: [-99.21331184488425, 19.426879165197107],
  },
  {
    avatar: 'sofia',
    name: 'Cancino',
    description:
      "The best pizza in Mexico. Alec is so Mexican now that the Diavola's spicy flavor doesn't faze him.",
    descES:
      '¡La mejor pizza de México! Alec es tan mexicano que el picante de la Diavola ya no le asusta.',
    lnglat: [-99.1961944737197, 19.431347888382227],
  },
  {
    avatar: 'both',
    name: 'Home',
    nameES: 'Casa',
    description:
      "We spent a great part of our time in Mexico playing all sorts of board games, especially Continental Rummy (and sometimes Catan) with Sofía's mother, her brother Eduardo, and Eduardo's fiancée, Ale. ",
    descES:
      'Pasamos la mayor parte de nuestro tiempo en México jugando juegos de mesa, especialmente Contintental Rummy (y algunas veces Catán) con la mamá de Sofía, su hermano Eduardo y su prometida Ale. ',
    lnglat: [-99.20190005837752, 19.44187459431031],
  },
  {
    avatar: 'sofia',
    name: 'Wine & Paint',
    description:
      "This is where we spent our first Valentine's Day in Mexico City, and where I learned that Alec's artistic abilities surpass mine... by a lot!",
    descES:
      'Aquí celebramos nuestro primer Día de San Valentín en la Ciudad de México. Es aquí también en donde aprendí que las habilidades artísticas de Alec son mejores que las mías....¡por mucho!',
    lnglat: [-99.2149583025553, 19.418500394701528],
  },
  {
    avatar: 'both',
    name: 'Auditorio Nacional',
    description: 'We saw Juanes perform here and had a blast!',
    descES: '¡Aquí vimos a Juanes y la pasamos increíble!',
    lnglat: [-99.1947324809596, 19.425679857451616],
  },
  {
    avatar: 'alec',
    name: 'Ojo de Agua',
    description: 'Sofía is addicted to the acai bowls here!',
    descES: '¡Sofía es adicta a los acai bowls de aquí!',
    lnglat: [-99.18991481789703, 19.433546732563066],
  },
  {
    avatar: 'sofia',
    name: 'McKinsey & Company',
    description: 'This is where I worked and met some of my best friends—meet them at the wedding!',
    descES:
      '¡Aquí es donde trabajé y conocí a algunos de mis mejores amigos—conózcanlos en la boda!',
    lnglat: [-99.20351515042933, 19.4253280034638],
  },
];

const VALLE_PLACES: Array<PlaceInfo> = [
  {
    avatar: 'sofia',
    name: 'Bar Bura',
    description:
      "Alec proposed here! If you've never heard the play-by-play of that day, do ask Alec, it's a great story.",
    descES:
      '¡Aquí fue en donde Alec me propuso matrimonio! Si no saben los detalles de ese día, pregúntenle a Alec, es una gran historia.',
    lnglat: [-116.75389164463967, 31.95186224825505],
  },
];

const HOUSTON_PLACES: Array<PlaceInfo> = [
  {
    hotelPreview: true,
    avatar: '',
    name: 'The Bell Tower on 34th',
    description: 'We are getting married here!',
    descES: '¡Aquí nos vamos a casar!',
    lnglat: [-95.41589635767276, 29.81758591012734],
  },
  {
    hotelPreview: true,
    avatar: '',
    name: "The Great Lawn at St. John's School",
    description: 'The welcome party will take place here!',
    descES: '¡Aquí será la fiesta de bienvenida!',
    lnglat: [-95.42932674470703, 29.742898213426077],
  },
  {
    hotelPreview: true,
    avatar: '',
    name: "Aunt Bonnie's Home",
    nameES: 'Casa de la Tía Bonnie',
    description: 'The farewell brunch will be over here!',
    descES: '¡Aquí será el brunch de despedida!',
    lnglat: [-95.4279327, 29.7108316],
  },
  {
    hotelPreview: 'https://www.granducahouston.com/',
    avatar: '',
    name: 'Hotel Granduca',
    description: '1080 Uptown Park Blvd\nHouston, Texas\n(713) 418-1000',
    descES: '1080 Uptown Park Blvd\nHouston, Texas\n(713) 418-1000',
    lnglat: [-95.45823567356149, 29.75778579105596],
  },
  // {
  //   hotelPreview: 'https://www.omnihotels.com/hotels/houston',
  //   avatar: '',
  //   name: 'Omni Houston Hotel',
  //   description: '4 Riverway\nHouston Texas\n(713) 871-8181',
  //   descES: '4 Riverway\nHouston Texas\n(713) 871-8181',
  //   lnglat: [-95.4593725, 29.761787811857904],
  // },
  {
    hotelPreview: 'https://www.hyatt.com/en-US/hotel/texas/hyatt-regency-houston-galleria/hourg',
    avatar: '',
    name: 'Hyatt Regency Houston Galleria',
    description: '2626 Sage Rd\nHouston, TX\n(832) 803-1234',
    descES: '2626 Sage Rd\nHouston, TX\n(832) 803-1234',
    lnglat: [-95.46728363863464, 29.739212400621316],
  },
  {
    hotelPreview:
      'https://www.marriott.com/en-us/hotels/hougw-the-westin-galleria-houston/overview/',
    avatar: '',
    name: 'The Westin Galleria Houston',
    description: '5060 W Alabama St\nHouston, TX\n(713) 960-8100',
    descES: '5060 W Alabama St\nHouston, TX\n(713) 960-8100',
    lnglat: [-95.46459317231864, 29.738588672031685],
  },
  // {
  //   hotelPreview:
  //     'https://www.marriott.com/en-us/hotels/houjw-jw-marriott-houston-by-the-galleria/overview/',
  //   avatar: '',
  //   name: 'JW Marriott Houston by The Galleria',
  //   description: '5051 Westheimer\nHouston, Texas\n(713) 961-1500',
  //   descES: '5051 Westheimer\nHouston, Texas\n(713) 961-1500',
  //   lnglat: [-95.46668282385589, 29.741858042917848],
  // },
  {
    avatar: 'alec',
    name: "Little Pappasito's",
    description:
      'The best beef and chicken fajitas you will ever taste—remember to order extra guac! This is where Sofía learned that Tex-Mex is not the same as Mexican food, and that Tex-Mex is certainly delicious as well.',
    descES:
      'Aquí probarán las mejores fajitas de carne y pollo, ¡no se olviden de ordenar guacamole extra! Aquí Sofía aprendió que la comida Tex-Mex no es lo mismo que la comida mexicana y también aquí aprendió que la comida Tex-Mex es deliciosa.',
    lnglat: [-95.41788336931619, 29.73596071143178],
  },
  {
    avatar: 'sofia',
    name: 'Pappas Burger',
    description:
      "Between Shake Shack and Pappas Burger, I don't know which burger is best. They have also heard that the pecan pie at Pappas Burger is amazing, let us know how it is!",
    descES:
      'Entre Shake Shack y Pappas Burger, no sabemos cuál lugar tiene la mejor hamburguesa. Nos enteramos que Pappas Burger tiene un pie de nuez delicioso, ¡si lo pruebas, dinos qué tal está!',
    lnglat: [-95.36794992897151, 29.75397715035979],
  },
  {
    avatar: 'both',
    name: 'Common Bond Cafe',
    description: 'Great place for a quick pastry and coffee!',
    descES: '¡Excelente lugar para un buen pan dulce y café!',
    lnglat: [-95.36794992897151, 29.75397715035979],
  },
  {
    avatar: 'both',
    name: "Tiny's No. 5",
    description: 'Great place for breakfast!',
    descES: '¡Gran lugar para un buen desayuno!',
    lnglat: [-95.43379885301427, 29.718204882142217],
  },
  {
    avatar: 'both',
    name: 'The Galleria',
    description: 'Shopping, shopping, and more shopping! ',
    descES: '¡Shopping, shopping y más shopping!',
    lnglat: [-95.46448816012521, 29.73939951288034],
  },
  {
    avatar: 'both',
    name: 'Highland Village',
    description: 'A spot for great shopping!',
    descES: '¡Un lugar para ir de shopping!',
    lnglat: [-95.44603247348867, 29.74267939335219],
  },
  {
    avatar: 'both',
    name: 'Memorial Park',
    description: "Houston's largest urban park!",
    descES: '¡El parque urbano más grande de Houston!',
    lnglat: [-95.44078940794851, 29.766600788596293],
  },
  {
    avatar: 'both',
    name: 'Hermann Park',
    description:
      'This park is the closet one to the festivities! You can visit the zoo, enjoy pedal boating, or take a nice stroll.',
    descES:
      '¡Este parque es el más cercano a la celebración! Pueden visitar el zoológico, disfrutar de un bote de pedales o de una agradable caminata.',
    lnglat: [-95.38923716931617, 29.71365053740854],
  },
  {
    avatar: 'both',
    name: 'The Museum of Fine Arts',
    description:
      'This museum houses an amazing collection of modern and contemporary art, it is also conveniently located in front of Hotel ZaZa.',
    descES:
      'Este museo tiene una increíble colección de arte moderno y contemporáneo y está justo enfrente del Hotel ZaZa.',
    lnglat: [-95.3902753079485, 29.726330991538585],
  },
  {
    avatar: 'both',
    name: 'Downtown Aquarium',
    description:
      'The aquarium houses a Ferris wheel, an aquatic carousel, and a white tiger exhibit!',
    descES:
      '¡El acuario tiene una rueda de la fortuna, un carrusel acuático y una exhibición de tigres blancos!',
    lnglat: [-95.3673792, 29.76516438589593],
  },
];

const IMPORTANT_PLACES: Array<Array<PlaceInfo>> = [
  BAY_AREA_PLACES,
  MADRID_PLACES,
  CDMX_PLACES,
  VALLE_PLACES,
  HOUSTON_PLACES,
];

// TODO fix avatar "floating" so it doesn't overlap
const AVATAR_SIZE = 40;
function Avatar(props: { speaker: 'alec' | 'sofia' | 'both' }) {
  if (props.speaker === 'both') {
    return (
      <View style={[{ flexDirection: 'row' }]}>
        <View style={{ marginRight: 5 }}>
          <Avatar speaker="sofia" />
        </View>
        <Avatar speaker="alec" />
      </View>
    );
  }

  return (
    <View
      style={[shadow, { borderRadius: AVATAR_SIZE, width: AVATAR_SIZE, height: AVATAR_SIZE }]}
      // @ts-expect-error
      dataSet={{ avatar: '' }}
    >
      <Image
        source={props.speaker === 'alec' ? alec : sofia}
        style={[
          {
            width: AVATAR_SIZE,
            height: AVATAR_SIZE,
            borderRadius: AVATAR_SIZE,
          },
          shadow,
        ]}
      />
    </View>
  );
}

function getBoundsForImportantPlaces(index: number, hotelPreview: boolean | undefined) {
  const bounds = new LngLatBounds();
  bounds.extend((route.features[index]?.geometry.coordinates[0] as any) ?? VENUE);
  IMPORTANT_PLACES[index].forEach((place) => {
    if (!hotelPreview || place.hotelPreview) {
      bounds.extend(place.lnglat);
    }
  });
  return bounds;
}

const route = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString' as const,
        coordinates: [BAY_AREA, MADRID],
      },
    },
    {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString' as const,
        coordinates: [MADRID, CDMX],
      },
    },
    {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString' as const,
        coordinates: [CDMX, BAR_BURA],
      },
    },
    {
      type: 'Feature' as const,
      properties: {},
      geometry: {
        type: 'LineString' as const,
        coordinates: [BAR_BURA, VENUE],
      },
    },
  ],
};

const currentRoute = {
  type: 'FeatureCollection',
  features: route.features.map(() => ({
    type: 'Feature' as const,
    properties: {},
    geometry: {
      type: 'LineString' as const,
      coordinates: [],
    },
  })),
};

const point = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature' as const,
      properties: { bearing: 0 },
      geometry: {
        type: 'Point' as const,
        coordinates: route.features[0].geometry.coordinates[0],
      },
    },
  ],
};

// Number of steps to use in the arc and animation, more steps means
// a smoother arc and animation, but too many steps will result in a
// low frame rate
let steps = [200, 200, 200, 200, 200];
let zooms = [10.5, 12, 11, 11, 11];

function calculateArc(index: number) {
  // Calculate the distance in kilometers between route start/end point.
  let lineDistance = length(route.features[index]);

  let arc = [];

  // Draw an arc between the `origin` & `destination` of the two points
  for (let i = 0; i < lineDistance; i += lineDistance / steps[index]) {
    let segment = along(route.features[index], i);
    arc.push(segment.geometry.coordinates);
  }

  arc.push(route.features[index].geometry.coordinates.reverse()[0]);

  // Update the route with calculated arc coordinates
  route.features[index].geometry.coordinates = arc;
}

route.features.forEach((_, i) => calculateArc(i));

const INITIAL_ZOOM: [number] = [10];

function CircleMarker({ disabled, isActive }: { disabled?: boolean; isActive?: boolean }) {
  return (
    <View
      style={[
        shadow,
        {
          borderRadius: 15,
          backgroundColor: isActive ? '#888' : 'black',
          borderWidth: 2,
          borderColor: 'white',
          width: 20,
          height: 20,
        },
      ]}
      // @ts-ignore
      dataSet={{
        marker: '',
        ...(isActive ? { 'marker-active': '' } : {}),
        ...(disabled ? { disabled: '' } : {}),
      }}
    />
  );
}

export function OurPathMap(props: { hotelPreview?: boolean }) {
  const startAtActivities =
    props.hotelPreview || global.window.location.search?.includes('activities=true');
  const mapRef = useRef<MapboxJS.Map>();
  const [index, setIndex] = useState(startAtActivities ? IMPORTANT_PLACES.length - 1 : -1);
  const [showAirplane, setShowAirplane] = useState(false);
  const [showCardContent, setShowCardContent] = useState(false);
  const [activePlace, setActivePlace] = useState<PlaceInfo | null>(null);
  const t = useT();
  const { lang } = useI18nContext();
  let { width, height } = useWindowDimensions();

  if (props.hotelPreview) {
    height = 500;
  }

  const isMobile = width < 800;
  const mountedRef = useRef(false);
  const startRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>(0 as any);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      mapRef.current = undefined;
    };
  }, []);

  const animate = useCallback(() => {
    setShowAirplane(true);
    return new Promise<MapboxJS.LngLatLike>((resolve) => {
      let counter = 0;
      const coordinates = route.features[index].geometry.coordinates;

      function anim() {
        let start = coordinates[counter >= steps[index] ? counter - 1 : counter];
        let end = coordinates[counter >= steps[index] ? counter : counter + 1];

        if (!start || !end) {
          setShowAirplane(false);
          resolve(coordinates[coordinates.length - 1] as any);
          return;
        }

        // Update point geometry to a new position based on counter denoting
        // the index to access the arc
        point.features[0].geometry.coordinates = coordinates[counter];
        currentRoute.features[index].geometry.coordinates.push(
          // @ts-ignore
          point.features[0].geometry.coordinates,
        );

        // Calculate the bearing to ensure the icon is rotated to match the route arc
        // The bearing is calculated between the current point and the next point, except
        // at the end of the arc, which uses the previous point and the current point
        point.features[0].properties.bearing = bearing(turfPoint(start), turfPoint(end));

        // Update the source with this new data
        // @ts-expect-error
        mapRef.current?.getSource('point').setData(point);
        // @ts-expect-error
        mapRef.current?.getSource('route').setData(currentRoute);

        counter = counter + 1;
        if (mountedRef.current) {
          requestAnimationFrame(anim);
        }
      }

      anim();
    });
  }, [index]);

  const cardContent = activePlace ? (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={{ marginBottom: 18, flex: 1, marginRight: 10 }} size={20}>
          {lang === 'es' && activePlace.nameES ? activePlace.nameES : activePlace.name}
        </Text>
        {activePlace.avatar ? <Avatar speaker={activePlace.avatar} /> : null}
      </View>
      <Text style={{ textAlign: 'justify', marginTop: 20 }}>
        {lang === 'en' ? activePlace.description : activePlace.descES}
      </Text>
    </View>
  ) : props.hotelPreview ? null : index === 0 ? (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
        <Text size={24} style={{ textAlign: 'justify', marginBottom: 18 }} weight="medium">
          {t('save_our_date.our_story_body_bay_heading')}
        </Text>
        <Avatar speaker="alec" />
      </View>
      <Text style={{ textAlign: 'justify' }}>{t('save_our_date.our_story_body_bay')}</Text>
    </View>
  ) : index === 1 ? (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
        <Text size={24} style={{ textAlign: 'justify', marginBottom: 18 }} weight="medium">
          {t('save_our_date.our_story_body_madrid_heading')}
        </Text>
        <Avatar speaker="alec" />
      </View>
      <Text style={{ textAlign: 'justify' }}>{t('save_our_date.our_story_body_madrid')}</Text>
    </View>
  ) : index === 2 ? (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
        <Text size={24} style={{ textAlign: 'justify', marginBottom: 18 }} weight="medium">
          {t('save_our_date.our_story_body_mexico_heading')}
        </Text>
        <Avatar speaker="sofia" />
      </View>
      <Text style={{ textAlign: 'justify' }}>{t('save_our_date.our_story_body_mexico')}</Text>
    </View>
  ) : index === 3 ? (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
        <Text size={24} style={{ textAlign: 'justify', marginBottom: 18 }} weight="medium">
          {t('save_our_date.our_story_body_engagement_heading')}
        </Text>
        <Avatar speaker="sofia" />
      </View>
      <Text style={{ textAlign: 'justify' }}>{t('save_our_date.our_story_body_engagement')}</Text>
    </View>
  ) : index === 4 ? (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
        <Text size={24} style={{ textAlign: 'justify', marginBottom: 18 }} weight="medium">
          {t('save_our_date.our_story_body_houston_heading')}
        </Text>
        <Avatar speaker="both" />
      </View>
      <Text style={{ textAlign: 'justify' }}>{t('save_our_date.our_story_body_houston')}</Text>
    </View>
  ) : null;

  const padding = isMobile
    ? { top: 20, left: 20, right: 20, bottom: 20 + 200 }
    : { top: 50 + 100, left: 300 + 100, right: 100, bottom: 100 };

  const reset = () => {
    setIndex(-1);
    mapRef.current?.fitBounds(
      getBoundsForImportantPlaces(IMPORTANT_PLACES.length - 1, props.hotelPreview),
      {
        animate: true,
        padding,
        duration: 1000,
      },
    );

    currentRoute.features.forEach((feature) => {
      feature.geometry.coordinates = [];
    });
    // @ts-expect-error
    mapRef.current?.getSource('route').setData(currentRoute);
  };

  const start = () => {
    if (mapRef.current) {
      startRef.current = false;
      setIndex(0);
      mapRef.current.fitBounds(getBoundsForImportantPlaces(0, props.hotelPreview), {
        animate: true,
        padding,
        duration: 1000,
      });
    } else {
      startRef.current = true;
      return new Promise(() => {});
    }
  };

  return (
    <View>
      <Map
        center={props.hotelPreview ? [-95.45823567356149, 29.75778579105596] : (VENUE as any)}
        zoom={props.hotelPreview ? [11] : INITIAL_ZOOM}
        // style="mapbox://styles/mapbox/light-v10"
        style="mapbox://styles/appscanvas/ckog6xzyy0x2817o81rk6qjk0"
        onStyleLoad={(map) => {
          mapRef.current = map;
          setShowCardContent(true);
          if (startRef.current) {
            start();
          }
        }}
        containerStyle={{
          // fit inside mobile height of SaveOurDateScreen container to allow easier scrolling back up
          height: height + (isMobile ? -100 : 0),
          width: '100%',
          backgroundColor: '#d2d5d5',
        }}
      >
        {isMobile ? null! : <ZoomControl position="bottom-right" />}
        <GeoJSONLayer
          id="route"
          data={currentRoute}
          lineLayout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          linePaint={{
            'line-color': '#888',
            'line-width': 2,
          }}
        />
        <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
          <Feature coordinates={route.features[0].geometry.coordinates[0]} />
          <Feature coordinates={route.features[1].geometry.coordinates[0]} />
          <Feature coordinates={route.features[2].geometry.coordinates[0]} />
          <Feature
            coordinates={
              route.features[2].geometry.coordinates[
                route.features[2].geometry.coordinates.length - 1
              ]
            }
          />
        </Layer>
        {showAirplane ? (
          <GeoJSONLayer
            id="point"
            data={point}
            symbolLayout={{
              'icon-image': 'airport-15',
              'icon-rotate': ['get', 'bearing'],
              'icon-rotation-alignment': 'map',
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
            }}
          />
        ) : (
          null!
        )}
        <>
          {showCardContent
            ? IMPORTANT_PLACES[index]
                ?.filter((p) => !props.hotelPreview || p.hotelPreview)
                .map((place) => {
                  const isActive = activePlace?.name === place.name;
                  return (
                    <Marker
                      coordinates={place.lnglat}
                      anchor="bottom"
                      key={place.name}
                      onClick={() => {
                        setActivePlace(place);
                      }}
                    >
                      <CircleMarker isActive={isActive} />
                    </Marker>
                  );
                })
            : null}
        </>
      </Map>
      {index === -1 ? (
        <ScrollView
          style={[
            shadow,
            {
              position: 'absolute',
              backgroundColor: 'rgba(255,255,255,0.8)',
              zIndex: 10,
            },
            isMobile
              ? {
                  left: 0,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  padding: 20,
                }
              : {
                  top: 250,
                  left: '50%',
                  transform: [{ translateX: '-50%' }] as any,
                  width: 700,
                  padding: 20,
                },
          ]}
          // @ts-expect-error
          dataSet={{
            'scroll-view': '',
          }}
        >
          <Text size={24} style={{ textAlign: 'justify', marginBottom: 10 }} weight="medium">
            {t('save_our_date.welcome_heading')}
          </Text>
          <Text style={{ textAlign: 'justify', marginBottom: 20 }}>
            {t('save_our_date.welcome_body')}
            <View style={{ position: 'relative', top: 5, marginHorizontal: 10 }}>
              <CircleMarker isActive={false} disabled />
            </View>
            {t('save_our_date.welcome_body_outro')}
          </Text>

          <Button
            text={t('our_path_map.next_button')}
            style={{ alignSelf: 'flex-end', marginTop: 30 }}
            variant="solid"
            size="large"
            onPress={start}
          />
        </ScrollView>
      ) : showCardContent && cardContent ? (
        <ScrollView
          fadingEdgeLength={50}
          style={[
            shadow,
            {
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: 10,
            },
            props.hotelPreview
              ? {
                  left: 20,
                  top: 20,
                  bottom: 20,
                  width: 300,
                }
              : isMobile
              ? {
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: 200,
                }
              : {
                  // prevent overlap with "SOFIA+ALEC" banner
                  top: width < 1200 && !isMobile ? '40%' : 52 + 50,
                  left: 50,
                  bottom: 50,
                  width: 300,
                },
          ]}
          contentContainerStyle={{ padding: 20 }}
          // @ts-expect-error
          dataSet={{
            'scroll-view': '',
          }}
        >
          {cardContent}
          {activePlace ? (
            typeof activePlace.hotelPreview === 'string' ? (
              <Button
                text={t('our_path_map.visit_button')}
                to={activePlace.hotelPreview}
                style={{ alignSelf: 'flex-start', marginTop: 30 }}
              />
            ) : (
              <Button
                text={t('our_path_map.back_button')}
                onPress={() => setActivePlace(null)}
                style={{ alignSelf: 'flex-start', marginTop: 30 }}
              />
            )
          ) : index === 4 ? (
            <Button
              text="Reset"
              style={{ alignSelf: 'flex-end', marginTop: 30 }}
              variant="solid"
              size="large"
              onPress={reset}
            />
          ) : (
            <Button
              text={t('our_path_map.next_button')}
              style={{ alignSelf: 'flex-end', marginTop: 30 }}
              variant="solid"
              size="large"
              onPress={() => {
                if (index === -1) {
                  setIndex(0);
                  mapRef.current?.fitBounds(getBoundsForImportantPlaces(0, props.hotelPreview), {
                    animate: true,
                    padding,
                    duration: 1000,
                  });
                  return;
                }
                setShowCardContent(false);

                const bounds = new LngLatBounds();
                route.features[index].geometry.coordinates.forEach((coord) => {
                  // @ts-expect-error
                  bounds.extend(coord);
                });

                mapRef.current!.fitBounds(bounds, { animate: true, padding, duration: 1000 });
                timeoutRef.current = setTimeout(() => {
                  animate().then((lastCoord) => {
                    if (IMPORTANT_PLACES[index + 1]) {
                      mapRef.current?.fitBounds(
                        getBoundsForImportantPlaces(index + 1, props.hotelPreview),
                        {
                          animate: true,
                          padding,
                          duration: 1000,
                          maxZoom: 11,
                        },
                      );
                    } else {
                      mapRef.current?.flyTo({
                        zoom: zooms[index + 1],
                        center: lastCoord,
                        duration: 1000,
                      });
                    }
                    setTimeout(() => {
                      setIndex(index + 1);
                      setShowCardContent(true);
                    }, 1000);
                  });
                }, 1000);
              }}
            />
          )}
        </ScrollView>
      ) : null}
    </View>
  );
}
