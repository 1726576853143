import React, { useRef, useState } from 'react';
import { Image, useWindowDimensions } from 'react-native';

import { Section } from '@src/components/Section';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { TextInput } from '@src/components/TextInput';
import { readData } from '@src/lib/firebase';
import { useT } from '@src/lib/useT';

function sanitizeEmail(email: string) {
  return email.trim().toLowerCase();
}

const TICKET_WIDTH = 300;

function Ticket() {
  return (
    <Image
      source={require('@src/assets/Billete_sencillo_del_metro_(Madrid).png')}
      style={{ width: TICKET_WIDTH, height: TICKET_WIDTH / 2.3 }}
    />
  );
}

export function TableScreen() {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const [email, setEmail] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const t = useT();
  const [invalid, setInvalid] = useState(false);
  const [result, setResult] = useState<GuestlistRow>();

  async function lookupInfo(email: string): Promise<GuestlistRow | undefined> {
    const res = await readData<GuestlistRow | { refEmail: string }>(
      'addresses',
      sanitizeEmail(email),
    );
    if (res && 'refEmail' in res) {
      return lookupInfo(res.refEmail);
    }
    return res;
  }

  if (result) {
    return (
      <Section
        style={{ flex: 1, padding: 20 }}
        innerStyle={{ maxWidth: 1200, alignItems: 'center' }}
      >
        <Image
          source={require('@src/assets/Logomarca_negro.png')}
          style={isMobile ? { width: (800 * 2) / 3, height: 200 } : { width: 800, height: 300 }}
          resizeMode="contain"
        />
        <View
          style={{ height: 50, width: 10, backgroundColor: 'black', marginTop: -60, left: -3 }}
        />
        <View
          style={{
            backgroundColor: 'white',
            padding: 20,
            marginBottom: 60,
            borderWidth: 4,
            borderBottomWidth: 12,
            borderColor: 'black',
          }}
        >
          <Text size={50} weight="heavy" style={{ fontFamily: 'sans-serif', textAlign: 'center' }}>
            {result.tableName}
          </Text>
        </View>
        <Ticket />
      </Section>
    );
  }

  async function lookup() {
    const lookupResult = await lookupInfo(email);
    if (!lookupResult) {
      setInvalid(true);
    } else if (lookupResult && !lookupResult.tableName) {
      setInvalid(true);
    } else {
      setResult(lookupResult);
    }
  }

  return (
    <Section style={{ flex: 1 }}>
      <Text size={32} style={{ textAlign: 'center' }}>
        {t('metro.heading')}
      </Text>
      {invalid ? (
        <Text style={{ textAlign: 'center', color: '#A3333D', marginVertical: 12 }}>
          {t('metro.no_result_error')}
        </Text>
      ) : null}
      <View
        style={{
          marginTop: 30,
          padding: isMobile ? 24 : 46,
          backgroundColor: 'rgba(255,255,255,0.6)',
        }}
      >
        <form ref={formRef}>
          <View spacing={8}>
            <Text weight="medium" size={20}>
              {t('metro.description')}
            </Text>
            <TextInput
              label={t('contact_info.email_label')}
              value={email}
              onChangeValue={setEmail}
              autoCompleteType="email"
              keyboardType="email-address"
              required
              onSubmitEditing={lookup}
            />
          </View>
          <View style={{ marginTop: 40, alignItems: 'center' }}>
            <Button text={t('metro.lookup_button')} size="large" variant="solid" onPress={lookup} />
          </View>
        </form>
      </View>
      <Image
        source={require('@src/assets/Logomarca_negro.png')}
        style={{ width: 1, height: 1 }}
        resizeMode="contain"
      />
      <View style={{ height: 0, width: 0, overflow: 'hidden' }}>
        <Ticket />
      </View>
    </Section>
  );
}
