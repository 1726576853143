import React from 'react';
import { StyleProp, Text as RNText, TextStyle } from 'react-native';

const FONTS = {
  light: 'AvenirLight',
  regular: 'Avenir',
  medium: 'AvenirMedium',
  heavy: 'AvenirHeavy',
};

const WEIGHTS = {
  light: '200',
  regular: '400',
  medium: '500',
  heavy: '700',
} as const;

export function Text({
  weight = 'regular',
  size = 16,
  children,
  style,
}: {
  weight?: 'light' | 'regular' | 'medium' | 'heavy';
  size?: number;
  children?: React.ReactNode;
  style?: StyleProp<TextStyle>;
}) {
  return (
    <RNText
      style={[
        {
          color: 'black',
          fontSize: size,
          fontWeight: WEIGHTS[weight],
          fontFamily: FONTS[weight],
        },
        style,
      ]}
    >
      {children}
    </RNText>
  );
}
