import React, { useRef, useState } from 'react';
import { StyleProp, ViewStyle, useWindowDimensions } from 'react-native';
import { Link } from 'react-router-dom';
import { shadow } from '@src/constants';

import { Section } from '@src/components/Section';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { PickerInput, TextInput } from '@src/components/TextInput';
import { useForm } from '@src/lib/useForm';
import { readData, writeData } from '@src/lib/firebase';
import { useT } from '@src/lib/useT';
import { Switch } from '@src/components/Switch';

function RegistryItem(props: {
  buttonText: string;
  description: string;
  link: string;
  style?: StyleProp<ViewStyle>;
}) {
  const t = useT();
  return (
    <View
      style={[
        {
          flex: 1,
        },
        props.style,
      ]}
    >
      <View
        style={[
          shadow,
          {
            padding: 20,
            paddingVertical: 50,
            backgroundColor: 'white',
          },
        ]}
      >
        <Button to={props.link} text={props.buttonText} style={{ alignSelf: 'center' }} />
      </View>
      <Text
        size={15}
        style={{
          textAlign: 'center',
          marginTop: 20,
          alignSelf: 'center',
        }}
      >
        {props.description}
      </Text>
    </View>
  );
}

export function RegistryScreen(props: {}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const t = useT();
  return (
    <View style={{ flexGrow: 1 }}>
      <Section style={{ flexGrow: 1 }}>
        <View spacing={40}>
          <Text
            size={18}
            style={{
              letterSpacing: 2,
              textAlign: 'center',
              marginBottom: 20,
              maxWidth: 700,
              alignSelf: 'center',
            }}
          >
            {t('registry.intro')}
          </Text>
          <View
            row={!isMobile}
            spacing={isMobile ? 50 : 20}
            style={isMobile ? undefined : { alignItems: 'flex-start' }}
          >
            <RegistryItem
              link="https://www.kuhl-linscomb.com/registry.php?uid=98422"
              buttonText={t('registry.kuhl.button')}
              description={t('registry.kuhl.description')}
            />
            {false ? (
              <RegistryItem
                link="https://www.amazon.com/wedding/share/sofia-and-alec"
                buttonText={t('registry.amazon.button')}
                description={t('registry.amazon.description')}
              />
            ) : null}
            <RegistryItem
              link="https://support.lupus.org/site/Donation2?idb=1166502778&df_id=4202&4202.donation=form1&mfc_pref=T&s_subsrc=button_nav_donate&s_src=lupus.org&4202_donation=form1"
              buttonText={t('registry.lupus.button')}
              description={t('registry.lupus.description')}
            />
          </View>
        </View>
      </Section>
    </View>
  );
}
