import React, { useState } from 'react';
import { ActivityIndicator, Pressable, StyleProp, ViewStyle } from 'react-native';

import { Text } from '@src/components/Text';

export function Button(props: {
  style?: StyleProp<ViewStyle>;
  text: string;
  to?: string;
  onPress?: () => void | Promise<unknown>;
  size?: 'large';
  variant?: 'solid';
}) {
  const [loading, setLoading] = useState(false);
  const contentColor = props.variant === 'solid' ? 'white' : 'black';

  return (
    <Pressable
      accessibilityRole={props.to ? 'link' : 'button'}
      onPress={async () => {
        const result = props.onPress?.();
        if (result && Promise.resolve(result) === result) {
          setLoading(true);
          try {
            await result;
          } finally {
            setLoading(false);
          }
        }
      }}
      style={() => {
        return [
          {
            borderRadius: 4,
            borderWidth: 2,
            borderColor: 'black',
            backgroundColor: props.variant === 'solid' ? 'black' : undefined,
            padding: 10,
            paddingHorizontal: props.size === 'large' ? (loading ? 25 : 40) : 10,
            flexDirection: 'row',
          },
          props.style,
        ];
      }}
      // @ts-ignore
      href={props.to}
      // @ts-ignore
      target={props.to?.startsWith('https://') ? '_blank' : null}
      // @ts-ignore
      dataSet={{
        button: '',
        'button-solid': props.variant === 'solid' ? '' : undefined,
      }}
    >
      {loading ? <ActivityIndicator color="white" style={{ marginRight: 10 }} /> : null}
      <Text style={{ marginTop: 4, color: contentColor }} weight="medium">
        {props.text}
      </Text>
    </Pressable>
  );
}
