import React, { useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Section } from '@src/components/Section';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { PickerInput, TextInput } from '@src/components/TextInput';
import { useForm } from '@src/lib/useForm';
import { readData, writeData } from '@src/lib/firebase';
import { useT } from '@src/lib/useT';

function sanitizeEmail(email: string) {
  return email.trim().toLowerCase();
}

export function ConfirmInfoScreen(props: {}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const { data, bind } = useForm({ email: '' });
  const formRef = useRef<HTMLFormElement>(null);
  const t = useT();
  const [invalid, setInvalid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [needsCorrection, setNeedsCorrection] = useState(false);
  const [result, setResult] = useState<GuestlistRow>();

  async function lookupInfo(email: string): Promise<GuestlistRow | undefined> {
    const res = await readData<GuestlistRow | { refEmail: string }>(
      'addresses',
      sanitizeEmail(email),
    );
    if (res && 'refEmail' in res) {
      return lookupInfo(res.refEmail);
    }
    return res;
  }

  if (needsCorrection) {
    return (
      <Section style={{ flex: 1 }}>
        <View
          style={{
            marginTop: 30,
            padding: isMobile ? 24 : 46,
            backgroundColor: 'rgba(255,255,255,0.6)',
          }}
        >
          <form ref={formRef}>
            <View spacing={8}>
              <Text weight="medium" size={24} style={{ marginTop: 40 }}>
                {t('contact_info.address_heading')}
              </Text>
              <PickerInput
                items={[
                  'United States',
                  'México',
                  'Canada',
                  'España',
                  'Estonia',
                  'Finland',
                  'United Kingdom',
                ]}
                {...bind('country')}
                label={t('contact_info.country_label')}
              />
              <TextInput
                label={t('contact_info.address_line_1_label')}
                {...bind('addressLine1')}
                autoCompleteType="street-address"
                required
              />
              <TextInput label={t('contact_info.address_line_2_label')} {...bind('addressLine2')} />
              <View row={!isMobile} spacing={8}>
                <TextInput label={t('contact_info.city_label')} {...bind('city')} required />
                {data.country === 'México' ? (
                  <TextInput label={t('contact_info.delegation_label')} {...bind('delegation')} />
                ) : null}
                {data.country === 'España' ||
                data.country === 'Estonia' ||
                data.country === 'United Kingdom' ? null : (
                  <TextInput label={t('contact_info.state_label')} {...bind('state')} required />
                )}
                <TextInput
                  label={t('contact_info.postal_code_label')}
                  {...bind('postalCode')}
                  autoCompleteType="postal-code"
                  required
                />
              </View>
            </View>
            <View
              style={{
                marginTop: 40,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <Button
                text={t('confirm_info.submit_button')}
                size="large"
                variant="solid"
                onPress={async () => {
                  setInvalid(false);
                  formRef.current?.classList.remove('invalid');
                  const valid = formRef.current?.checkValidity();
                  if (valid) {
                    const newData: GuestlistRow & { updated: boolean; verified: true } = {
                      ...result,
                      'Street Address': data.addressLine1 || '',
                      'Street Address Line 2': data.addressLine2 || '',
                      City: data.city || '',
                      Country: data.country || '',
                      Delegación: data.delegation || '',
                      'State/Region': data.state || '',
                      'Zip/Postal Code': data.postalCode || '',
                      updated: true,
                      verified: true,
                    } as any;
                    await writeData(
                      'addresses',
                      sanitizeEmail(result!['Email Main Guest']),
                      newData,
                    );
                    setSubmitted(true);
                    setNeedsCorrection(false);
                  } else {
                    setInvalid(true);
                    global.window.scrollTo({ top: 0, behavior: 'smooth' });
                    formRef.current?.classList.add('invalid');
                  }
                }}
              />
            </View>
          </form>
        </View>
      </Section>
    );
  }

  if (result) {
    return (
      <Section style={{ flex: 1 }}>
        {submitted ? null : (
          <Text size={32} style={{ textAlign: 'center' }}>
            {t('confirm_info.result_heading')}
          </Text>
        )}
        <View
          style={{
            marginTop: 30,
            padding: isMobile ? 24 : 46,
            backgroundColor: 'rgba(255,255,255,0.6)',
          }}
        >
          {submitted ? (
            <View style={{ alignItems: 'center' }} spacing={16}>
              <Text style={{ textAlign: 'center', maxWidth: 400 }}>
                {t('confirm_info.success')}
              </Text>
            </View>
          ) : (
            <form>
              <View spacing={8}>
                <Text weight="medium" size={24} style={{ textAlign: 'center' }}>
                  {result['Street Address']}
                  {'\n'}
                  {result['Street Address Line 2']}
                  {result['Street Address Line 2'] ? '\n' : ''}
                  {result['City']}
                  {', '}
                  {result['State/Region'] || ''}{' '}
                  {result['Country'] !== 'United States' ? result['Country'] + ' ' : ' '}
                  {result['Zip/Postal Code']}
                </Text>
              </View>
              <View
                style={{
                  marginTop: 40,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  text={t('confirm_info.incorrect_button')}
                  size="large"
                  onPress={async () => {
                    setNeedsCorrection(true);
                  }}
                  style={{ marginRight: 20 }}
                />
                <Button
                  text={t('confirm_info.correct_button')}
                  size="large"
                  onPress={async () => {
                    await writeData('addresses', sanitizeEmail(result!['Email Main Guest']), {
                      ...result,
                      verified: true,
                    });
                    setSubmitted(true);
                  }}
                />
              </View>
            </form>
          )}
        </View>
      </Section>
    );
  }

  async function lookup() {
    const lookupResult = await lookupInfo(data.email);
    if (!lookupResult) {
      setInvalid(true);
    } else {
      setResult(lookupResult);
    }
  }

  return (
    <Section style={{ flex: 1 }}>
      <Text size={32} style={{ textAlign: 'center' }}>
        {t('confirm_info.heading')}
      </Text>
      {invalid ? (
        <Text style={{ textAlign: 'center', color: '#A3333D', marginVertical: 12 }}>
          {t('confirm_info.no_result_error')}
        </Text>
      ) : null}
      <View
        style={{
          marginTop: 30,
          padding: isMobile ? 24 : 46,
          backgroundColor: 'rgba(255,255,255,0.6)',
        }}
      >
        <form ref={formRef}>
          <View spacing={8}>
            <Text weight="medium" size={20}>
              {t('confirm_info.description')}
            </Text>
            <TextInput
              label={t('contact_info.email_label')}
              {...bind('email')}
              autoCompleteType="email"
              keyboardType="email-address"
              required
              onSubmitEditing={lookup}
            />
          </View>
          <View style={{ marginTop: 40, alignItems: 'center' }}>
            <Button
              text={t('confirm_info.lookup_button')}
              size="large"
              variant="solid"
              onPress={lookup}
            />
          </View>
        </form>
      </View>
    </Section>
  );
}
