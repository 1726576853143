import React, { useRef, useEffect, useState } from 'react';
import {
  TouchableWithoutFeedback,
  Animated,
  Switch as RNSwitch,
  View,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink as RRNavLink,
  Redirect,
} from 'react-router-dom';
import Hamburger from 'hamburger-react';
import * as Sentry from '@sentry/react';

import { BACKGROUND } from '@src/constants';
import './src/global.css';
import { Text } from '@src/components/Text';
import { Switch as PillSwitch } from '@src/components/Switch';
import { SaveOurDate } from '@src/SaveOurDateScreen';
import { CovidScreen } from '@src/CovidScreen';
import { TravelScreen } from '@src/TravelScreen';
import { EventsScreen } from '@src/EventsScreen';
import { I18nProvider, useI18nContext, useT } from '@src/lib/useT';
import { ContactInfoScreen } from '@src/ContactInfoScreen';
import { Section } from '@src/components/Section';
import { ConfirmInfoScreen } from '@src/ConfirmInfoScreen';
import { RSVPScreen } from '@src/RSVPScreen';
import { RegistryScreen } from '@src/RegistryScreen';
import { TableScreen } from '@src/TableScreen';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://b329ab220a524add8c6bc75e11b9049e@o669691.ingest.sentry.io/5767483',
  });
}

function NavLink(props: React.ComponentProps<Link> & { text: string }) {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  return (
    <RRNavLink
      style={{
        alignItems: 'center',
        borderBottomColor: 'transparent',
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        borderTopColor: 'transparent',
        borderTopStyle: 'solid',
        borderTopWidth: 2,
        display: 'flex',
        marginLeft: 12,
        marginRight: 12,
        padding: 12,
        textDecoration: 'none',
      }}
      activeStyle={
        isMobile
          ? {
              borderRadius: 8,
              backgroundColor: '#d2d5d5',
            }
          : {
              borderBottomColor: 'black',
            }
      }
      exact
      {...props}
    >
      {props.text ? (
        <Text weight="regular" size={20}>
          {props.text}
        </Text>
      ) : (
        props.children
      )}
    </RRNavLink>
  );
}

function LanguageSwitcher() {
  const { lang, setLang } = useI18nContext();
  return (
    <View
      style={{ paddingLeft: 10, justifyContent: 'center' }}
      // @ts-expect-error
      dataSet={{ 'no-select': '' }}
    >
      <PillSwitch
        falseLabel="EN"
        trueLabel="ES"
        value={lang === 'es'}
        onChangeValue={() => {
          if (lang === 'es') {
            setLang('en');
          } else {
            setLang('es');
          }
        }}
      />
    </View>
  );
}

function NavBar() {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const [isOpen, setIsOpen] = useState(false);
  const t = useT();

  useEffect(() => {
    if (!isMobile) setIsOpen(false);
  }, [isMobile]);

  function close() {
    setIsOpen(false);
  }

  const links = (
    <>
      <NavLink to="/" text={t('navbar.home')} onClick={close} />
      <NavLink to="/events" text={t('navbar.events')} onClick={close} />
      <NavLink to="/travel" text={t('navbar.travel')} onClick={close} />
      <NavLink to="/registry" text={t('navbar.registry')} onClick={close} />
      <NavLink to="/rsvp" text={t('navbar.rsvp')} onClick={close} />
    </>
  );

  return (
    <View
      style={{
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: isOpen ? 'white' : 'rgba(255,255,255,0.4)',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          elevation: 4,
          shadowColor: '#26372f',
          shadowRadius: 5,
          shadowOpacity: 1,
        }}
      >
        <LanguageSwitcher />
        {isMobile ? (
          <Hamburger toggled={isOpen} toggle={setIsOpen} />
        ) : (
          <View style={{ flexDirection: 'row' }}>{links}</View>
        )}
      </View>
      {isOpen ? <View style={{ backgroundColor: 'white', padding: 8 }}>{links}</View> : null}
    </View>
  );
}

function Screen(props: { children?: React.ReactNode }) {
  return (
    <ScrollView
      style={{ paddingTop: 52, flex: 1, backgroundColor: BACKGROUND }}
      contentContainerStyle={{
        flexGrow: 1,
        // padding: 24,
      }}
    >
      {props.children}
    </ScrollView>
  );
}

function NotFound() {
  const t = useT();
  return (
    <Section>
      <Text weight="medium" size={20} style={{ textAlign: 'center' }}>
        {t('not_found')}
      </Text>
    </Section>
  );
}

function ErrorPage() {
  useEffect(() => {
    (global as any).asdf();
  }, []);
  return null;
}

const CONTACT_FORM_ONLY = false;

export function InnerApp() {
  useFonts({
    Avenir: require('./src/assets/font/AvenirNextLTPro-Regular.otf'),
    AvenirMedium: require('./src/assets/font/AvenirNextLTPro-MediumCn.otf'),
    // AvenirHeavy: require('./src/assets/font/AvenirNextLTPro-HeavyCn.otf'),
    // AvenirLight: require('./src/assets/font/AvenirNextLTPro-UltLtCn.otf'),
  });

  if (CONTACT_FORM_ONLY) {
    return (
      <Router>
        <StatusBar style="auto" />
        <Switch>
          <Route path={['/contact', '/contacto']}>
            <Screen>
              <ContactInfoScreen />
            </Screen>
          </Route>
          <Route path={['/contacts', '/contactos']}>
            <Screen>
              <ContactInfoScreen multiple />
            </Screen>
          </Route>
          <Route path="*">
            <Screen>
              <NotFound />
            </Screen>
          </Route>
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      <StatusBar style="auto" />
      <NavBar />
      <Switch>
        <Route path="/events">
          <Screen>
            <EventsScreen />
          </Screen>
        </Route>
        <Route path="/travel">
          <Screen>
            <TravelScreen />
          </Screen>
        </Route>
        <Route path={['/confirm-info', '/confirmar-info']}>
          <Screen>
            <ConfirmInfoScreen />
          </Screen>
        </Route>
        <Route path="/rsvp">
          <Screen>
            <RSVPScreen />
          </Screen>
        </Route>
        <Route path="/registry">
          <Screen>
            <RegistryScreen />
          </Screen>
        </Route>
        <Route path="/metro">
          <Screen>
            <TableScreen />
          </Screen>
        </Route>
        <Route path="/error">
          <Screen>
            <ErrorPage />
          </Screen>
        </Route>
        <Route exact path="/">
          <SaveOurDate />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default function App() {
  return (
    <I18nProvider>
      <InnerApp />
    </I18nProvider>
  );
}
