import React, { useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';
// @ts-expect-error
import uuid from 'uuid/v4';

import { Section } from '@src/components/Section';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { PickerInput, TextInput } from '@src/components/TextInput';
import { useForm } from '@src/lib/useForm';
import { writeData } from '@src/lib/firebase';
import { useI18nContext, useT } from '@src/lib/useT';

const ENGLISH_TITLES = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Hon.', 'Rabbi', 'Other'];
const SPANISH_TITLES = ['Sr.', 'Sra.', 'Srita.', 'Dr.', 'Dra.', 'Lic.', 'Otro'];

export function ContactInfoScreen(props: { multiple?: boolean }) {
  const [id] = useState(uuid);
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const { data, bind } = useForm({});
  const hasTwoGuests = !!props.multiple;
  const formRef = useRef<HTMLFormElement>(null);
  const [invalid, setInvalid] = useState(false);
  const t = useT();
  const { lang } = useI18nContext();
  const [submitted, setSubmitted] = useState(false);
  const [isOtherTitle, setIsOtherTitle] = useState(false);
  const [isOtherTitle2, setIsOtherTitle2] = useState(false);

  function submit() {
    setInvalid(false);
    formRef.current?.classList.remove('invalid');
    const valid = formRef.current?.checkValidity();
    if (valid) {
      return writeData('contacts', id, data).then(() => {
        setSubmitted(true);
      });
    } else {
      setInvalid(true);
      global.window.scrollTo({ top: 0, behavior: 'smooth' });
      formRef.current?.classList.add('invalid');
    }
    return;
  }

  useEffect(() => {
    if (data.title === 'Other' || data.title === 'Otro') {
      setIsOtherTitle(true);
      bind('title').onChangeValue('');
    }
    if (data.title2 === 'Other' || data.title2 === 'Otro') {
      setIsOtherTitle2(true);
      bind('title2').onChangeValue('');
    }
    // eslint-disable-next-line
  }, [data.title, data.title2]);

  return (
    <Section>
      {submitted ? null : (
        <Text size={32} style={{ textAlign: 'center' }}>
          {t('contact_info.heading')}
        </Text>
      )}
      {invalid ? (
        <Text style={{ textAlign: 'center', color: '#A3333D', marginVertical: 12 }}>
          {t('contact_info.required_fields_error')}
        </Text>
      ) : null}
      <View
        style={{
          marginTop: 30,
          padding: isMobile ? 24 : 46,
          backgroundColor: 'rgba(255,255,255,0.6)',
        }}
      >
        {submitted ? (
          <View style={{ alignItems: 'center' }} spacing={16}>
            <Text style={{ textAlign: 'center', maxWidth: 400 }}>{t('contact_info.success')}</Text>
          </View>
        ) : (
          <form ref={formRef}>
            <View spacing={8}>
              <Text weight="medium" size={24}>
                {t('contact_info.name_heading')}
              </Text>
              <View row={!isMobile} spacing={8}>
                {isOtherTitle ? (
                  <TextInput label={t('contact_info.title_label')} {...bind('title')} required />
                ) : (
                  <PickerInput
                    items={lang === 'en' ? ENGLISH_TITLES : SPANISH_TITLES}
                    {...bind('title')}
                    label={t('contact_info.title_label')}
                  />
                )}
                <TextInput
                  label={t('contact_info.first_name_label')}
                  {...bind('firstName')}
                  autoCompleteType="name"
                  required
                />
                <TextInput label={t('contact_info.middle_name_label')} {...bind('middleName')} />
                <TextInput
                  label={t('contact_info.last_name_label')}
                  {...bind('lastName')}
                  required
                />
              </View>
              <View row={!isMobile} spacing={8}>
                <TextInput
                  label={t('contact_info.email_label')}
                  {...bind('email')}
                  autoCompleteType="email"
                  keyboardType="email-address"
                  required
                />
              </View>
              {hasTwoGuests ? (
                <View spacing={8}>
                  <View
                    style={{
                      height: 1,
                      width: '80%',
                      backgroundColor: '#666',
                      marginTop: 16,
                      alignSelf: 'center',
                    }}
                  />
                  <View row={!isMobile} spacing={8}>
                    {isOtherTitle2 ? (
                      <TextInput
                        label={t('contact_info.title_label')}
                        {...bind('title2')}
                        required
                      />
                    ) : (
                      <PickerInput
                        items={lang === 'en' ? ENGLISH_TITLES : SPANISH_TITLES}
                        {...bind('title2')}
                        label={t('contact_info.title_label')}
                      />
                    )}
                    <TextInput
                      label={t('contact_info.first_name_label')}
                      {...bind('firstName2')}
                      required
                    />
                    <TextInput
                      label={t('contact_info.middle_name_label')}
                      {...bind('middleName2')}
                    />
                    <TextInput
                      label={t('contact_info.last_name_label')}
                      {...bind('lastName2')}
                      required
                    />
                  </View>
                  <View row={!isMobile} spacing={8}>
                    <TextInput
                      label={t('contact_info.email_label')}
                      {...bind('email2')}
                      autoCompleteType="email"
                      keyboardType="email-address"
                      required
                    />
                  </View>
                </View>
              ) : null}
            </View>

            <View spacing={8}>
              <Text weight="medium" size={24} style={{ marginTop: 40 }}>
                {t('contact_info.address_heading')}
              </Text>
              <PickerInput
                items={['United States', 'México', 'España', 'Estonia', 'United Kingdom', 'Canada']}
                {...bind('country')}
                label={t('contact_info.country_label')}
              />
              <TextInput
                label={t('contact_info.address_line_1_label')}
                {...bind('addressLine1')}
                autoCompleteType="street-address"
                required
              />
              <TextInput label={t('contact_info.address_line_2_label')} {...bind('addressLine2')} />
              <View row={!isMobile} spacing={8}>
                <TextInput label={t('contact_info.city_label')} {...bind('city')} required />
                {data.country === 'México' ? (
                  <TextInput label={t('contact_info.delegation_label')} {...bind('delegation')} />
                ) : null}
                {data.country === 'España' ||
                data.country === 'Estonia' ||
                data.country === 'United Kingdom' ? null : (
                  <TextInput label={t('contact_info.state_label')} {...bind('state')} required />
                )}
                <TextInput
                  label={t('contact_info.postal_code_label')}
                  {...bind('postalCode')}
                  autoCompleteType="postal-code"
                  required
                />
              </View>
              <View>
                <label>
                  <View style={{ marginTop: 16 }} row spacing={8}>
                    <View>
                      <input
                        type="checkbox"
                        value={data.changeOfAddressAnticipated}
                        onChange={(e) => {
                          bind('changeOfAddressAnticipated').onChangeValue(e.target.checked as any);
                        }}
                      />
                    </View>
                    <Text>{t('contact_info.change_addresses_label')}</Text>
                  </View>
                </label>
              </View>
            </View>

            <View style={{ marginTop: 40, alignItems: 'center' }}>
              <Button
                text={t('contact_info.save_button')}
                size="large"
                variant="solid"
                onPress={submit}
              />
            </View>
          </form>
        )}
      </View>
    </Section>
  );
}
